html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.sensor-overview {
    margin-top: 1rem;
}

.sensor-status-container {
    width: 100%;
    padding: 1rem;
    max-height: 70vh;
    overflow-y: auto;
}

.sensor-status-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    overflow-x: auto;
    padding: 0.125rem;
}

.sensor-status-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    width: calc(50% - 0.75rem);
    box-sizing: border-box;
    min-width: 3px;
    flex: 1 1 calc(50% - 0.75rem);
}

.status-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 0.75rem;
    flex-shrink: 0;
}

.status-dot.active {
    background-color: #4CAF50;
    box-shadow: 0 0 8px rgba(76, 175, 80, 0.8);
    animation: glow 1.5s ease-in-out infinite;
}

.status-dot.inactive {
    background-color: #F44336;
    box-shadow: 0 0 8px rgba(244, 67, 54, 0.8);
    animation: glow 1.5s ease-in-out infinite;
}

.sensor-info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: start;
}

.sensor-id {
    font-weight: bold;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sensor-name {
    color: #666;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes pulse-green {
    0% {
        box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
    }

    70% {
        box-shadow: 0 0 0 6px rgba(76, 175, 80, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
    }
}

@keyframes pulse-red {
    0% {
        box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.7);
    }

    70% {
        box-shadow: 0 0 0 6px rgba(244, 67, 54, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
    }
}

.Greeting {
    font-family: Montserrat, Arial;
    font-size: 20px;
    margin-bottom: 1rem;
}

.Instructions {
    font-family: Montserrat, Arial;
    font-size: 24px;
    margin-bottom: 0.1rem;
}

.status-container {
    align-items: center;
    justify-content: top;
    display: flex;
    flex-direction: column;
}

.diagram-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-container {
    font-family: Montserrat, Arial;
    border-radius: 8px;
    width: 70%;
    font-size: 0.9rem;
    display: block;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0px 4px 15px rgb(0, 0, 0);
    margin-bottom: 2rem;
    min-width: 50vw;
    max-height: 1000px;
    overflow-y: hidden;
    margin-bottom: 2rem;
}

.chart-container {
    box-shadow: 0px 4px 15px rgb(0, 0, 0);
    border-radius: 15px;
    padding: 40px;
    padding-left: 30px;
    padding-right: 10px;
    width: 70%;
    font-family: Montserrat, Arial;
    font-size: 14px;
}

.chart-title {
    transform: translateX(calc(75px - 1rem));
    font-family: Montserrat, Arial;
}

.graph {
    transform: translateX(calc(-75px + 1rem));
    height: 40vh;
    width: 100%;
}

.channel-selector {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;

}

.channel-button {
    padding: 8px 16px;
    border: 1px solid #414288;
    background: #cfcfec;
    color: #414288;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease;
}

.channel-button.active {
    background: #414288;
    color: white;
    box-shadow: 0px 4px 15px rgb(65, 66, 136);
}

.channel-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.sensor-container {
    font-family: Montserrat, Arial;
    border-radius: 8px;
    font-size: 0.9rem;
    display: block;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    width: 70%;
    margin: 20px auto;
    background-color: #ffffff;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.sensor-container.expanded {
    box-shadow: 0px 4px 15px rgb(0, 0, 0);
    max-height: 1000px;
    overflow-y: hidden;
}

.search-results {
    margin-top: 1rem;
}

.details-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.details-table th,
.details-table td {
    border: 1px solid #414288;
    padding: 8px;
    text-align: center;
}

.details-table th {
    background-color: #f2f2f2;
}

.search-container {
    width: 70%;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgb(0, 0, 0);
    padding: 25px;
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    margin-bottom: 3rem;
}

.search-container div {
    margin-bottom: 5px;
}

.or-line {
    font-size: large;
    display: flex;
    align-items: center;
    margin: 10px 0;
    position: relative;
}

.or-line span {
    margin: 0 auto;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 50%;
    color: #414288;
    font-weight: bold;
    z-index: 2;
}

.or-line::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #414288;
    top: 50%;
    left: 0;
    z-index: 1;
}

.search-container input,
.search-container button {
    margin: 10px 0;
    padding: 10px;
    width: 15%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.search-container input {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-family: Montserrat, Arial;
    transition: border-color 0.2s, box-shadow 0.2s;
    box-sizing: border-box;
}

.search-container label {
    display: block;
    margin-bottom: 6px;
    font-weight: 600;
    color: #414288;
    font-size: 0.9rem;
    width: auto;
}

.search-container button {
    background-color: #414288;
    color: white;
    cursor: pointer;
}

.search-container input:focus {
    outline: none;
    border-color: #414288;
    box-shadow: 0 0 0 2px rgba(65, 66, 136, 0.2);
}

.search-container button:hover {
    background-color: #414288;
}

.date-inputs {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.date-inputs div {
    flex: 1;
}

.fetch-group-devices,
.fetch-id-button button {
    background-color: #414288;
    color: white;
    padding: 12px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.2s, transform 0.1s;
    width: auto;
    min-width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fetch-group-devices:hover,
.fetch-id-button button:hover {
    background-color: #353470;
    transform: translateY(-1px);
}

.fetch-group-devices:active,
.fetch-id-button button:active {
    transform: translateY(1px);
}


.fetch-id-button,
.fetch-group-devices-button {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
}

.top-sensor-table {
    width: 100%;
    margin: 10px auto;
    overflow-x: auto;
}

.top-sensor-table table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    overflow: hidden;
    font-size: 13px;
    margin-top: 10px;
    table-layout: auto;
    min-width: 100px;
    max-width: 100%;
}

.top-sensor-table th,
.top-sensor-table td {
    padding: 8px;
    word-wrap: break-word;
    vertical-align: middle;
    white-space: nowrap;
    max-width: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.top-sensor-table th {
    background-color: #414288;
    color: white;
    border: 1px solid #414288;
}

.line-chart {
    font-family: Montserrat, Arial;
    margin-left: calc(70px - 1rem);
}

.sensor-table {
    width: 100%;
    margin: 10px auto;
    overflow-x: auto;
}

.sensor-table table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    overflow: hidden;
    font-size: 14px;
    margin-top: 10px;
    table-layout: auto;
    min-width: 300px;
}

.sensor-table th,
.sensor-table td {
    padding: 8px;
    word-wrap: break-word;
    vertical-align: middle;
}

.sensor-table th {
    background-color: #414288;
    color: white;
    border: 1px solid #414288;
}

.sensor-flex-row td {
    flex: 1;
    padding: 4px 8px;
    border: 1px solid #414288;
}

.sensor-paginate {
    display: flex;
    justify-content: center;
    color: #414288;
    align-items: center;
    gap: 0.5rem;
    width: auto;
    margin-top: 10px;
    overflow: hidden;
    padding: 0;
    font-weight: bold;
    list-style: none;
}

.sensor-paginate li {
    display: inline-block;
}

.sensor-paginate a {
    text-decoration: none;
    color: #414288;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sensor-paginate a:hover {
    background-color: #f0f0f0;
}

.sensor-paginate .selected a {
    background-color: #414288;
    color: white;
    border-color: #414288;
}

.sensor-paginate .selected a:hover {
    background-color: #414288be;
    color: white;
    border-color: #414288be;
}

.pagination-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

@media (max-width: 1028px) {
    .search-container {
        width: 90%;
    }

    .sensor-container {
        width: 90%;
    }

    .outage-box {
        width: 90% !important;
    }

    .graph {
        height: 30vh;
    }

    .chart-container {
        width: 90%;
    }

    .top-container {
        width: 90%;
    }

    .sensor-paginate {
        gap: 0.5rem;
        font-size: 13px;
    }
}

@media (max-width: 900px) {

    .sensor-container table {
        width: 100%;
    }
    
    .pagination-container {
        margin-top: 15px;
        width: 100%;
        overflow-x: visible;
    }
}

@media (max-width: 768px) {
    .search-container {
        width: 100%;
    }

    .search-container input,
    .search-container button {
        width: 100%;
    }

    .sensor-container {
        width: 100%;
    }

    .sensor-table th,
    .sensor-table td {
        padding: 2px;
        font-size: 12px;
    }

    .sensor-paginate {
        gap: 0.25rem;
        font-size: 10px;
    }

    .outage-box {
        width: 100% !important;
    }

    .chart-container {
        width: 100%;
    }

    .top-container {
        width: auto;
        overflow-x: auto;
    }
}

@media (max-width: 650px) {
    .sensor-paginate {
        gap: 0.1rem;
        font-size: 9px;
    }

    .sensor-status-grid {
        flex-wrap: wrap;
        min-width: calc(400px-0.75rem);
    }

}

@media (max-width: 530px) {

    /*To make it look better on mobile */
    .date-inputs {
        flex-direction: column;
        gap: 10px;
    }

    .date-inputs div {
        width: 100%;
    }

    .date-inputs input {
        width: 100%;
    }

    .search-container input {
        font-size: 14px;
    }

    .search-container label {
        font-size: 0.85rem;
    }
}

@media (max-width: 480px) {
    .search-container {
        width: 100%;
    }

    .search-container input,
    .search-container button {
        width: 100%;
    }

    .sensor {
        width: 45%;
    }

    .sensor-container {
        align-items: center;
    }

    .sensor-paginate {
        gap: 0.25rem;
        font-size: 8px;
    }

    .chart-container {
        width: 100%;
    }

    .outage-box {
        width: 100% !important;
    }
}

/* .outage-box has same style as search container for consistent look. */
.outage-box {
    font-family: 'Montserrat', Arial, sans-serif;
    width: 70%;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgb(0, 0, 0);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    background-color: #ffffff;
}

.outage-box canvas {
    width: 100% !important;
    height: auto !important;
    max-width: 100% !important;
    margin: 0 !important;
}

.outage-box.donut canvas {
    max-width: 250px !important;
    height: auto !important;
    margin: 0 auto !important;
}