.info-box {
  background-color: #f9f9f9;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  width: 15%;
  min-width: 250px;
  height: 100%;
  text-align: left;
}

.empty-state {
  font-family: Montserrat, Arial;
  padding: 20px;
  margin: 0 auto;
}

.map-wrapper {
  position: relative;
  transition: all 0.3s ease;
}

.side-panel{
  max-height: 85vh;
}

/* Desktop styles */
@media (min-width: 993px) {
  .map {
    flex-direction: row;
  }

  .map-wrapper {
    flex: 1;
    height: 100vh;
    order: 2;
  }
}

/* Mobile styles */
@media (max-width: 992px) {
  .map {
    flex-direction: column;
    min-height: 100vh;
    width: 100% !important;
  }

  .map-wrapper {
    width: 100%;
    height: 50vh;
    position: relative;
    order: 1;
  }
  .side-panel{
    max-height: 50vh;
  }
}

.map-wrapper.notes-open {
  width: calc(100% - 300px);
}

.info-box h2 {
  font-size: 18px;
  font-weight: bold;
}

.info-box p {
  margin: 10px 0;
  font-size: 14px;
}

.mapboxgl-popup-content {
  text-align: left;
}

.mapboxgl-marker {
  cursor: pointer !important;
}

.map-wrapper {
  flex: 1;
  position: relative;
  height: 85%;
  width: 100%;
}

.map-container {
  width: 100%;
  height: 100%;
}

.map-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 100%;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 3;
}

.map-overlay .overlay-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: auto;
}

.map-overlay .coordinates {
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}

.map-overlay .reset-button {
  padding: 6px 10px;
  background-color: #414288;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-left: 10px;
}

.map-overlay .controls {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.map-overlay .control-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.map-overlay .control-label:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.map-overlay .control-label input[type='checkbox'] {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.map-overlay .control-label select {
  margin-left: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  padding: 4px;
  border-radius: 4px;
}

.map-overlay .control-label select:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.2);
}

.map-overlay .control-label option {
  background-color: white;
  color: black;
}

.control-group {
  display: flex;
  align-items: center;
  position: relative;
}

.rail-type-options-button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  cursor: pointer;
  padding: 5px 8px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.rail-type-options-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.rail-type-options-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.rail-type-options-button .arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.rail-type-options-button .arrow.down {
  border-top: 6px solid white;
}

.rail-type-options-button .arrow.up {
  border-bottom: 6px solid white;
}

.rail-type-options {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  min-width: 200px;
}

.rail-type-options::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.8);
}

.rail-type-label {
  margin-bottom: 5px;
  color: white;
  white-space: nowrap;
}

.rail-type-label input[type='checkbox'] {
  margin-right: 5px;
}

.tab-content {
  font-family: Montserrat, Arial;
}


@media (min-width: 2561px) {
  .map-overlay {
    width: 20%;
  }
}

@media (max-width: 2560px) {
  .map-overlay {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  .map-overlay {
    width: 55%;
  }
}



/* Mobile adjustments */
@media (max-width: 992px) {
  .info-box {
    border-right: none;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    padding-bottom: 0px;
  }

  .info-box h2 {
    font-size: 13px;
    font-weight: bold;
  }

  .info-box p {
    font-size: 13px;
  }

  .map-overlay {
    top: 5px;
    bottom: auto;
    left: 5px;
    flex-direction: column;
    align-items: flex-start;
  }

  .map-overlay .overlay-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .map-overlay .coordinates {
    font-size: 12px;
  }

  .map-overlay .controls {
    width: 100%;
  }

  .map-overlay .control-label {
    font-size: 12px;
  }

  .map-overlay .reset-button {
    margin-left: 0;
    margin-top: 0px;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .map-overlay {
    top: 10px;
    bottom: auto;
    left: 10px;
    right: 10px;
    width: calc(80% - 20px);
    max-width: none;
    flex-direction: column;
    align-items: flex-start;
  }

  .map-overlay .overlay-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .map-overlay .controls {
    width: 100%;
    margin-top: 10px;
  }

  .map-overlay .reset-button {
    margin-left: 0;
  }
  .side-panel{
    max-height: 40vh;
    transform: translateY(-2vh);
  }
}

@media (max-width: 480px) {
  .map {
    margin-left: auto;
    width: 100%;
    flex-direction: column;
  }

  .info-box {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    padding-bottom: 0px;
  }

  .info-box h2 {
    font-size: 12px;
    font-weight: bold;
  }

  .info-box p {
    margin-top: -10px;
    font-size: 11px;
  }

  .map-wrapper {
    height: 50vh;
    width: 100%;
  }

  .mapboxgl-popup {
    max-width: 160px;
  }

  .mapboxgl-popup-content {
    text-align: left;
    font-size: 11px;
    padding-bottom: 0px;
    padding-left: 5px;
  }

  .map-overlay {
    top: 10px;
    margin-left: -10px;
    width: 100% !important;
    max-width: none;
    flex-direction: column;
    align-items: flex-start;
    transform: translateY(-32vw);
  }

  .map-overlay .coordinates {
    font-size: 11px;
  }

  .map-overlay .reset-button {
    font-size: 11px;
    margin-top: 0px;
  }

  .mapboxgl-popup-content {
    font-family: Montserrat, Arial;
  }

  .map-overlay .controls {
    width: 100%;
    margin-top: 2px;
  }

  .map-overlay .control-label {
    font-size: 11px;
  }

  .map-overlay .control-label select {
    font-size: 11px;
  }
}

@media (max-width: 360px) {
  .map-overlay {
    transform: translateY(-40vw);
  }
}

.marker {
  cursor: pointer;
}