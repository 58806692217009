.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;

  .not-found-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-style: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .not-found-message {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
  }
  
  .not-found-info {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: #888;
    margin-bottom: 10px;
  }
  
  .info-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }
  
  .not-found-link {
    margin-top: 30px;
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #414288;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
}